import React from 'react'
import { Flex, Text, Button } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'

/* Component displayed if there's no cards in the database */

const NoCards = () => {
    return (
        <Flex flexDirection="column" justifyContent="center" alignItems="flex-start" minH="sm" minW="sm" maxW="md">
            <Text>Add some cards to get started with Minderva.</Text>
            <Button size="lg" colorScheme="teal" variant="outline" style={{ textDecoration: 'none' }} as={NavLink} to="/add-cards" mt={10}>Add Cards</Button>
        </Flex>
    )
}

export default NoCards