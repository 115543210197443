import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/functions";
import "firebase/compat/firestore";
// import {get} from "http";
// import 'firebase/analytics'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCYXWuTG81le1hu6O1NY-ZEvWQzWq2gHLg",
  authDomain: "minderva-17770.firebaseapp.com",
  databaseURL: "https://minderva-17770.firebaseio.com",
  projectId: "minderva-17770",
  storageBucket: "minderva-17770.appspot.com",
  messagingSenderId: "1021902086638",
  appId: "1:1021902086638:web:228fd61bc05371210beeff",
  measurementId: "G-ZVJSWDQTTM",
};
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
// firebase.analytics();

export const firestore = app.firestore();
export const auth = firebase.auth();
export const functions = firebase.functions();

export const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => auth.signInWithPopup(provider);
export const signOut = () => auth.signOut();

// Just for debugging
window.firebase = firebase;

export default firebase;
