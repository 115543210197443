import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// Chakra Styling
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import customTheme from './components/theme';
const domNode = document.getElementById('root')!;
const root = createRoot(domNode);



root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ChakraProvider theme={customTheme}>
        <CSSReset />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ChakraProvider>
    </HelmetProvider>
  </React.StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
